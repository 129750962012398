import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "instructors" }
const _hoisted_2 = { class: "instructors__block" }
const _hoisted_3 = { class: "instructors__main-wrap" }
const _hoisted_4 = { class: "table__mobile-wrap" }
const _hoisted_5 = { class: "table__selects" }
const _hoisted_6 = { class: "mobile-menu-wrap" }
const _hoisted_7 = { class: "instructors__filters-wrap" }
const _hoisted_8 = { class: "table__head" }
const _hoisted_9 = { class: "table__head-id" }
const _hoisted_10 = { class: "table__sort-block" }
const _hoisted_11 = { class: "table__head-big-tr" }
const _hoisted_12 = { class: "table__sort-block" }
const _hoisted_13 = { class: "table__head-big-tr" }
const _hoisted_14 = { class: "table__sort-block" }
const _hoisted_15 = { class: "table__head-big-tr" }
const _hoisted_16 = { class: "table__sort-block" }
const _hoisted_17 = { class: "table__head-schedule-tr" }
const _hoisted_18 = { class: "table__sort-block" }
const _hoisted_19 = { class: "table__body-id" }
const _hoisted_20 = { class: "table__body-big-tr" }
const _hoisted_21 = { class: "table__body-big-tr" }
const _hoisted_22 = { class: "table__body-big-tr" }
const _hoisted_23 = { class: "table__body-schedule-tr" }
const _hoisted_24 = { class: "table__progress-bar-wrap" }
const _hoisted_25 = { class: "table__progress-bar-count" }
const _hoisted_26 = { class: "instructors__popup-buttons" }
const _hoisted_27 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminPanelNavMenu = _resolveComponent("AdminPanelNavMenu")!
  const _component_AnalyticsNavMenu = _resolveComponent("AnalyticsNavMenu")!
  const _component_AnaliticMenuSVG = _resolveComponent("AnaliticMenuSVG")!
  const _component_Select = _resolveComponent("Select")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminPanelNavMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AnalyticsNavMenu, { class: "instructors__nav-menu desctop-menu" }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_AnaliticMenuSVG, {
              class: "mobile-mene-svg",
              onClick: _ctx.toggleMenu
            }, null, 8, ["onClick"]),
            _createElementVNode("div", {
              class: _normalizeClass({
                'instructors__nav-menu': true,
                'mobile-menu': true,
                activeMobileMenu: _ctx.isMenuActive,
              })
            }, [
              _createVNode(_component_AnalyticsNavMenu)
            ], 2)
          ])), [
            [_directive_click_outside, _ctx.closeMenu]
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Select, {
              data: _ctx.dataAllInstructors,
              "select-name": "All Instructors",
              class: "instructors__select",
              title: 'full_name',
              onOptionSelected: _cache[0] || (_cache[0] = 
                (option) => _ctx.handleOptionSelected('instructor_id', option)
              )
            }, null, 8, ["data"]),
            _createVNode(_component_Select, {
              data: _ctx.dataAllStudents,
              "select-name": "All Students",
              class: "instructors__select",
              title: 'full_name',
              onOptionSelected: _cache[1] || (_cache[1] = 
                (option) => _ctx.handleOptionSelected('student_id', option)
              )
            }, null, 8, ["data"]),
            _createVNode(_component_Select, {
              data: _ctx.dataLevels,
              "select-name": "All levels",
              class: "instructors__select instructors__select-levels",
              title: 'title',
              onOptionSelected: _cache[2] || (_cache[2] = 
                (option) => _ctx.handleOptionSelected('level_id', option)
              )
            }, null, 8, ["data"]),
            _createElementVNode("div", {
              class: "instructors__reset-filters",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.resetFilters && _ctx.resetFilters(...args)))
            }, " Reset filters ")
          ])
        ]),
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "table__title" }, "Time spent in the program", -1)),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[16] || (_cache[16] = _createTextVNode(" ID ")),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (this.getAnalytics('?sort=', 'id')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (this.getAnalytics('?sort=', '-id'))),
                class: "table__sort-bottom"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[17] || (_cache[17] = _createTextVNode(" Student ")),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[6] || (_cache[6] = ($event: any) => (this.getAnalytics('?sort=', 'student_name')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (this.getAnalytics('?sort=', '-student_name'))),
                class: "table__sort-bottom"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[18] || (_cache[18] = _createTextVNode(" Instructor ")),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (this.getAnalytics('?sort=', 'instructor_name')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[9] || (_cache[9] = ($event: any) => (this.getAnalytics('?sort=', '-instructor_name'))),
                class: "table__sort-bottom"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[19] || (_cache[19] = _createTextVNode(" Level ")),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getAnalytics('?sort=', 'level_id,')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[11] || (_cache[11] = ($event: any) => (this.getAnalytics('?sort=', '-level_id,'))),
                class: "table__sort-bottom"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[20] || (_cache[20] = _createTextVNode(" Time ")),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_SortArrow, {
                onClick: _cache[12] || (_cache[12] = ($event: any) => (this.getAnalytics('?sort=', 'time_spent')))
              }),
              _createVNode(_component_SortArrow, {
                onClick: _cache[13] || (_cache[13] = ($event: any) => (this.getAnalytics('?sort=', '-time_spent'))),
                class: "table__sort-bottom"
              })
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.analyticsData, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "table__body",
            key: item
          }, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(item.id), 1),
            _createElementVNode("div", _hoisted_20, _toDisplayString(item?.full_name), 1),
            _createElementVNode("div", _hoisted_21, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.instructors, (instructor) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: instructor.id
                }, _toDisplayString(instructor.full_name), 1))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_22, _toDisplayString(item?.current_level?.title), 1),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", {
                  class: "table__progress-bar",
                  style: _normalizeStyle({ width: item.months_spent_in_program * 10 + 'px' })
                }, null, 4),
                _createElementVNode("div", _hoisted_25, _toDisplayString(item.months_spent_in_program_for_humans), 1)
              ])
            ])
          ]))
        }), 128)),
        _createVNode(_component_Pagination, {
          propertyMeta: _ctx.analyticsMeta,
          propertyList: _ctx.analyticsData,
          page: _ctx.page,
          handlePage: _ctx.handlePage
        }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"])
      ])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "instructors__popup-title" }, "Are you sure?", -1)),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", {
                class: "instructors__popup-button-yes",
                onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.deleteInstructor && _ctx.deleteInstructor(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "instructors__popup-button-no",
                onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No "),
              (this.errorInstructors)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createVNode(_component_AlertBlock, {
                      message: this.errorInstructors
                    }, null, 8, ["message"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}